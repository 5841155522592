import React from "react";

const Footer = () => {
  return (
    <div className="mt-4 mb-4 faq">
      <div className="mb-2">
        <h3 id="price-list" className="mb-2" style={{ fontSize: "1.5rem" }}>
          Price List 2025
        </h3>
        <div className="mb-3" style={{ fontSize: "0.875rem" }}>
          We are dedicated to delivering exceptional value to each and every
          client. My commitment extends beyond mere transactions, aiming to
          foster great relationships through exceptional service and a
          personable approach. Regardless of your budget constraints, my goal of
          quality and value remains my highest priority.
        </div>
      </div>
      <div className="price-list">
        <div className="price-list-item">
          <div
            className="mb-0"
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              color: "#2d2d2d",
            }}
          >
            Landscape Maintenance
          </div>
          <div className="sub-text mb-2">
            All rates are hourly unless otherwise specified
          </div>
          <ul style={{ fontSize: "0.875rem" }}>
            <li>
              Mowing and edging{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              Weeding{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              Garden Bed Cleanup{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              Leaf Clean Up{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              Blowing{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              Planting and Watering{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              Pruning/Deadheading{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
            <li>
              General garden care{" "}
              <div>
                <span>$55</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="price-list-item">
          <div
            className="mb-0"
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              color: "#2d2d2d",
            }}
          >
            Lawn Management
          </div>
          <div className="sub-text mb-2">
            Price subject to change based on cost of seeds and fertilizer
          </div>
          <ul style={{ fontSize: "0.875rem" }}>
            <li>
              Power Raking/Aeration{" "}
              <div>
                <span>$100</span>
              </div>
            </li>
            <li>
              Seasonal Fertilization{" "}
              <div>
                <span>$40 per 1000 sq ft</span>
              </div>
            </li>
            <li>
              Moss Control Fertilization{" "}
              <div>
                <span>$40 per 1000 sq ft</span>
              </div>
            </li>
            <li>
              Overseeding{" "}
              <div>
                <span>$45 per 1000 sq ft</span>
              </div>
            </li>
            <li>
              Grub Control (Prevention and treatment)
              <div>
                <span>$75 per 1500 sq ft</span>
              </div>
            </li>
            <li>
              Hedge Trimming{" "}
              <div>
                <span>$80</span>
              </div>
            </li>
            <li>
              Power Washing{" "}
              <div>
                <span>$85</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
