import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import "./style.scss";
import logo from "../../images/westwind_logo_white_text.svg";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import SellRoundedIcon from "@mui/icons-material/SellRounded";
import { Waypoint } from "react-waypoint";

const MainNavbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  return (
    <>
      <Waypoint onEnter={() => setIsSticky(false)} />
      <Navbar
        className={`navbar-dark`}
        style={{
          top: isSticky ? "0" : "-100px",
          paddingBlock: isSticky ? ".25rem" : "1rem",
          position: isSticky ? "sticky" : "inherit",
          boxShadow: isSticky ? "rgba(0, 0, 0, 0.12) 0px 6px 16px" : 0,
          transition: "all 250ms ease-in-out",
          zIndex: 1,
          overflow: "visible",
        }}
        expand="md"
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Westwind Property Management logo" />
          </Navbar.Brand>
          <Navbar className="nav-right-menu">
            <Nav className="ml-auto">
              <Nav.Link className="nav-right-menu-link" href="#price-list">
                <SellRoundedIcon
                  aria-label="price tag"
                  sx={{ width: "18px", mr: "4px" }}
                />{" "}
                2025 Price list
              </Nav.Link>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
      <Waypoint onLeave={() => setIsSticky(true)} />
    </>
  );
};

export default MainNavbar;
